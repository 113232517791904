.ws-ls-card {
    width: 100%;
    height: 100%;
    min-width: 300px;
    min-height: 280px;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    border-radius: 10px;
}

.ws-card-img-bg {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, .9) 15%, rgba(83, 100, 141, 0) 70%);
}

.ws-ls-card-header {
    max-width: 80%;
    z-index: 9999 !important;
}