select {
    width: 100%;
    height: 50px;
}

select,
option {
    color: #fff;
    padding: 5px 25px;
    border-radius: 8px;
    background: var(--iq-body-bg);
    border: 1px solid #404043;
}

.ws-bg-select-plan:before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .99) 50%, rgba(83, 100, 141, 0) 100%) !important;
}

.ws-bg-credit-card:before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .9) 100%, rgba(83, 100, 141, 0) 100%) !important;
}

.isMonthly {
    display: flex;

    align-items: center;
    width: 210px;
    height: 42px;
    margin: 20px auto;
    border-radius: 21px;
    background-color: #272c32;

}

.isMonthly>div {
    width: 100%;
    display: flex;
    justify-content: center;



}

.isMonthlyActive {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 108px;
    height: 42px;
    border-radius: 21px;
    background-color: #be161a;
}

.ws-card-left {
    border-radius: 10px;
    border: solid 1px #272c32;
    padding: 20px 100px 30px 80px;
    background: #000;
}

.ws-card-right {
    border-radius: 10px;
    padding: 50px 100px 60px 80px;
    box-shadow: 0 32px 64px 0 rgba(57, 20, 0, 0.04);
    background-color: #14161a;
}

.ws-circle {
    margin-right: 10px;
}

.form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.2) !important;
}

.styleSelect-phone select {

    display: block;
    width: 100%;
    font-size: 1em;
    border: 1px solid #333;
    font-family: inherit;
    appearance: none;
    padding-left: 20% !important;
    background-image: url("../../../assets/icons/arrowdown.svg");
    background-repeat: no-repeat;
    background-size: 1.4em;
    background-position: 90% center !important;


}

.styleSelect {
    width: 100%;
}

.password-eye1 {
    position: absolute;
    right: 30px;
    top: 11px;
}


.plan-visible img {
    min-width: 16px !important;
}