
.wsAnswerCardImg {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1A1A1A ;
   
    color: #fff;
    text-align: left;
    
    padding: 0px 20px;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-repeat: no-repeat !important;
    background-size: 0% 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: 0.5s ease;

 
}
.wsAnswerCardImg:hover{
    padding-left: 20% !important;
    background-size: 30% 100% !important;
    background-color: #14161A ;
}
.wsAnswerCardImg:hover > .icon{
    display: block;
    transition: 0.5s ease;
}
.icon{
    display: none;
    transition: 0.5s ease;
}