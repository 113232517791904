.wsAbout {
    min-height: 300px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    text-align: center;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.wsAboutList {
    height: auto;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    text-align: center;
    background-position: center !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;

}

.wsPlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    /* background-image: linear-gradient(to bottom, rgba(19, 21, 23, 0) 17%, rgba(0, 0, 0, 0.7)); */
}

.wsPlayIslogin {

    background-image:
        linear-gradient(black, black);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: saturation;
    filter: grayscale(100%);

}

.wsCardImgBg {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, .9) 15%, rgba(83, 100, 141, 0) 50%);
}

.wsPlayIcon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(0.6985px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
}

.wsPlayIcon>img {
    /* margin-left: 7px; */
}

/*Time*/

.time {
    position: absolute;
    bottom: 20px;
    right: 10px;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 32px;
    -webkit-backdrop-filter: blur(6.4px);
    backdrop-filter: blur(6.4px);
    background-color: #be161a;
    z-index: 99;
}

.progressLine {
    height: 10px;
    background: #be161a;
    position: absolute;
    bottom: 0px;
    z-index: 99;
}

.progressLine1 {
    height: 10px;
    background: #272c32;
    position: absolute;
    bottom: 0px;
    z-index: 98;
}

.bg {
    background: #14161a !important;

}

.bgCard {
    display: flex;
    align-items: center;
    padding: 10px 30px;
    border-radius: 10px;
    min-height: 178px;
}

.adminEdit {
    position: absolute;
    right: 10px;
    top: 0px;
}